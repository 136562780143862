body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: dimgray;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
}